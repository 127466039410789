<template>
    <div v-loading.fullscreen.lock="loading">
        <over-time-del-search-bar-component
            add_auth="新增加班作废"
            :AllStaff="AllStaff"
            @search="get_over_time_del_index"
            @addTeam="addTeam"
        ></over-time-del-search-bar-component>
        <common-table-component
            details_auth="加班作废详情"
            :col_arr="fcol_arr"
            :table_data="ftable_data"
            @details_row="details_over_time_del"
        ></common-table-component>
        <common-page-component
            :current_page="cond.page"
            :page_size="cond.limit"
            :total="ftotal"
            @search="get_over_time_del_index"
        ></common-page-component>
        <over-time-del-edit-component
            edit_auth="修改加班作废"
            del_auth="删除加班作废"
            :id="fid"
            :show_type="edit_type"
            :dialogFormVisible="fdialogFormVisible"
            :over_time_del_details_data="fover_time_del_details_data"
            :AllStaff="AllStaff"
            @exitOverTimeDelDialog="dialogExit"
            @search="get_over_time_del_index"
            @show_edit="show_edit"
            @details_row="details_over_time_del"
        ></over-time-del-edit-component>
    </div>
</template>

<script>
import {
    over_time_del_index_request,
    over_time_del_details_request,
} from "@/network/hrm/OverTimeDel.js";

import { avatar_list_request,staff_list_request } from '@/network/list.js'

import OverTimeDelSearchBarComponent from "@/components/hrm/OverTimeDel/OverTimeDelSearchBarComponent";
import CommonTableComponent from "@/components/common/CommonTableComponent";
import CommonPageComponent from "@/components/common/CommonPageComponent";
import OverTimeDelEditComponent from "@/components/hrm/OverTimeDel/OverTimeDelEditComponent";

export default {
    name: "OverTimeDelView",
    data() {
        return {
            fid: 0,
            edit_type: 0,
            fdialogFormVisible: false,
            loading: false,
            ftotal: 0,
            cond: {
                staff_id: '',
                page: 1,
                limit: 10,
            },
            ftable_data: [],
            fcol_arr: [
                {
                    prop: "staff_name",
                    label: "员工",
                    minWidth: "100px",
                },
                {
                    prop: 'duration',
                    label: '作废时长',
                    minWidth: '100px'
                },
                {
                    prop: "create_staff_name",
                    label: "创建人",
                    minWidth: "100px",
                },
                {
                    prop: "create_time",
                    label: "创建时间",
                    minWidth: "100px",
                }
            ],
            fover_time_del_details_data: {},
            AllStaff: []
        }
    },
    computed: {},
    methods: {
        // 添加信息（弹框）
        addTeam() {
            this.edit_type = 1;
            this.fdialogFormVisible = true;
        },
        // 修改（弹框）
        show_edit() {
            this.edit_type = 2;
        },
        get_over_time_del_index(param = {}) {
            this.loading = true;
            this.cond.staff_id = param.staff_id ?? this.cond.staff_id;
            this.cond.page = param.page ?? this.cond.page;
            if (param.limit) {
                this.cond.limit = param.limit;
                this.cond.page = 1;
            }
            over_time_del_index_request(this.cond)
                .then((s) => {
                    this.loading = false;
                    if (s.status === 0) {
                        this.ftable_data = s.result.data;
                        this.ftotal = s.result.total;
                    } else {
                        this.$message.error(s.msg);
                    }
                })
                .catch((err) => {
                    this.loading = false;
                    this.$message.error(err);
                });
        },
        details_over_time_del(id) {
            this.fid = id;
            this.fdialogFormVisible = true;
            over_time_del_details_request(id)
                .then((s) => {
                    if (s.status === 0) {
                        this.fover_time_del_details_data = s.result;
                    } else {
                        this.$message.error(s.msg);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        dialogExit() {
            this.fdialogFormVisible = false;
            this.fid = 0;
            this.edit_type = 0;
        },
        get_all_staff() {
            staff_list_request()
                .then((s) => {
                    if (s.status === 0) {
                        this.AllStaff = s.result
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.loading = false
                    this.$message.error(err)
                })
        }
    },
    filters: {},
    props: {},
    created() {
        this.get_over_time_del_index()
        this.get_all_staff()
    },
    destoryed() {},
    mounted() {},
    updated() {},
    components: {
        OverTimeDelSearchBarComponent,
        CommonTableComponent,
        CommonPageComponent,
        OverTimeDelEditComponent,
    },
    watch: {},
};
</script>

<style lang="less"></style>
