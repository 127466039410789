import request from '../request'

//加班作废列表
export const over_time_del_index_request = p => {
    return request({
        method:'GET',
        url:'over_time_del/index',
        params: p
    })
}

//添加加班作废
export const over_time_del_add_request = d => {
    return request({
        method:'POST',
        url:'over_time_del/add',
        data: d
    })
}

//删除加班作废
export const over_time_del_del_request = id => {
    return request({
        method:'DELETE',
        url:'over_time_del/del',
        data: {
            id: id
        }
    })
}

//修改加班作废
export const over_time_del_edit_request = d => {
    return request({
        method:'PUT',
        url:'over_time_del/edit',
        data: {
            id: d.id,
            staff_id: d.staff_id,
            duration: d.duration
        }
    })
}

//获取加班作废详情
export const over_time_del_details_request = id => {
    return request({
        method:'GET',
        url:'over_time_del/details',
        params: {
            id
        }
    })
}